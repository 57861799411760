/** @jsx jsx */
import React, { useState } from 'react'
import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { jsx } from 'theme-ui'
import { getSrc } from 'gatsby-plugin-image'
import Layout from '../components/Layout'
import SEO from '../components/Seo'
import Container from '../components/Container'
import Grid from '../components/Grid'
import Tile from '../components/Tile'
import Video from 'react-responsive-video'

const propTypes = {
  data: PropTypes.object,
}

const Anwalt = ({ data }) => (
  <Layout>
    <SEO title="Anwalt" />

    <Container smallplus>
      <p sx={{ mb: 2 }}>Anwalt</p>
      <h1 sx={{ hyphens: `manual` }}>Friederike E.L. Vilmar</h1>
    </Container>

    <Grid large padding={0}>
      <Tile
        fluid={data.header.childImageSharp.gatsbyImageData}
        sx={{ bg: `gray.5`, height: [`240px`, `320px`, `400px`, `400px`] }}
      />
      <Tile
        fluid={data.image01.childImageSharp.gatsbyImageData}
        sx={{ bg: `gray.5`, height: [`240px`, `320px`, `400px`, `400px`] }}
      />
    </Grid>

    <Container smallplus>
      <div sx={{ pb: 4 }}>
        <table border="0" cellSpacing="0" cellPadding="10">
          <tbody>
            <tr>
              <th valign="top" align="left" scope="row">
                seit 2005
              </th>
              <td valign="top">Referentin an der hessischen Polizeifachschule</td>
            </tr>
            <tr>
              <th valign="top" align="left" scope="row">
                seit 2004
              </th>
              <td valign="top">Fachanwältin für Strafrecht mit Kanzlei in Frankfurt am Main</td>
            </tr>
            <tr>
              <th valign="top" align="left" scope="row">
                2000
              </th>
              <td>2. Juristisches Staatsexamen</td>
            </tr>
            <tr>
              <th valign="top" align="left" scope="row">
                1997 - 2000
              </th>
              <td>Referendariat in Frankfurt am Main und San Francisco / Kalifornien</td>
            </tr>
            <tr>
              <th valign="top" align="left" scope="row">
                1992 - 1997
              </th>
              <td>
                Studium der Rechtswissenschaften in Frankfurt am Main (1. Juristisches Staatsexamen)
              </td>
            </tr>
            <tr>
              <th valign="top" align="left" scope="row">
                1972
              </th>
              <td>Geboren in Lübeck</td>
            </tr>
          </tbody>
        </table>
      </div>
      <p>Arbeitssprachen: Deutsch, Englisch, Französisch</p>
    </Container>

    <Container smallplus>
      <h2>Porträt einer Strafverteidigerin</h2>
      <Video
        controls
        mp4={`/hr_FELV_Film_short.mp4`}
        imageVideo={getSrc(data.image02.childImageSharp.gatsbyImageData)}
      />
      <figcaption
        sx={{
          mt: [1, 2],
          float: `right`,
          fontSize: [0, 1, 2],
        }}
      >
        Quelle: hr fernsehen - Maintower Kriminalreport
      </figcaption>
    </Container>
  </Layout>
)

Anwalt.propTypes = propTypes

export default Anwalt

export const query = graphql`
  {
    header: file(relativePath: { eq: "rav/Friederike_Vilmar_BILD001_2_cut_bearbeiten.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
    image01: file(relativePath: { eq: "rav/mohammad-saifullah-egtGow8PYJ0-unsplash.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 900, placeholder: TRACED_SVG, layout: CONSTRAINED)
      }
    }
    image02: file(relativePath: { eq: "rav/videoPoster01.png" }) {
      childImageSharp {
        gatsbyImageData(width: 1000, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
  }
`
